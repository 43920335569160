<template>
  <div>
    
      <v-container>
        <h2 >Accounts</h2>
        <p class="text-dark">Access and manage the information specific to you like password, email address, company profile, activity, and security options.</p>
        <div>
        <v-row
         justify-xs="center"
          justify-sm="center"
          justify-md="start"
          justify="center"
          class=""
        >
          <v-col
            cols="12"
            md="6"
            lg="4"
            sm="12"
            v-for="content in contents"
            :key="content.name"
          >
            <v-hover >
              <template v-slot:default="{ hover }">
                <v-card
                  hover
                  :to="content.path ? content.path : false "
                  :elevation="hover ? 3 : 0"
                  :outlined="hover ? false : true"
                  class="card"
                  
                >
                  <div class="ma-lg-12 ma-sm-0">
                    <v-img :src="content.image" class="mx-auto mt-3" contain max-height="150"></v-img>
                  </div>
                  <v-card-text>
                    <p class="h6 text-dark "> {{ content.title }} </p>
                    <p class="text-secondary"> {{ content.desc }}</p>
                  </v-card-text>
                    <v-card-actions class="border-top py-3 text-right">
                      <router-link :to="content.path ? content.path : '#' " class="text-right w-100">
                        {{ content.btntext }}
                        <span><v-icon>mdi-keyboard_arrow_right</v-icon></span>
                      </router-link>
                    </v-card-actions>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
        </div>
      </v-container>
  </div>
</template>
<script>
export default {
  props: {
    myInfo: Object
  },
  data: () => ({
    contents: [

      {
     
        image: require("@/assets/payment.svg"),
        title: "My recent orders",
        desc:
          "View your order history and manage your orders and shipments.",
        btntext: "View order history",
        path: "/account/orderhistory",
      },
      {
    
        image: require("@/assets/savings.svg"),
        title: "My earnings",
        desc:
          "See how much money you’ve made from your referrals in our Affiliate Program.",
        btntext: "View my earnings",
        path: "/account/affiliate/earnings",
      },
      {
     
        image: require("@/assets/company.svg"),
        title: "My companies",
        desc:
          "You can view your affiliated companies or add your own company.",
        btntext: "View companies",
        path: "/account/mycompanies/",
      },
      {
        image: require("@/assets/products.svg"),
        title: "Our products",
        desc:
          "Our business solutions can ultimately improve your company’s workflows and streamline the overall business process.",
        btntext: "View products",
        path: "/account/servicessubscription",
      },
      {
      
        image: require("@/assets/discover.svg"),
        title: "Our commitment",
        desc:
          "In partnership with Amazon Web Services, Etousoft is committed to protect the integrity of information provided by our clients.",
        btntext: "Learn more",
        path: '/commitment',
        disabled : true,
      },
      {

        image: require("@/assets/security.svg"),
        title: "Update your security info",
        desc:
          "Make your account more secure by updating your personal information, security options, and other info related to your Etousoft account.",
        btntext: "Update security info",
        path: "/account/security",
      },
    ],
    user: [],
  }),
  created() {
      if(this.myInfo)
      {
        this.user = this.myInfo
      }
  },
  watch : {
    myInfo : function(val)
    {
      this.user = val
    }
  }
};
</script> 

<style scoped>
#content-link {
  text-decoration: none;
  color : #1976d2!important;
}
.card {
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
}
</style>